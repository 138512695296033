$color-classplus-blue: #009ae0;
$color-light-blue: #f7f9fb;
$color-pure-white: #ffffff;
$color-badge-green: #08bd80;
$color-stroke: #e9eef2;
$color-subtext: #666666;
$color-black: #1a181e;
$color-error-red: #e50b20;
$color-disabled-grey: #fafafa;
$hourglassbg: white;
$fill: var(--appAccentColor);

@mixin font-wt($wt) {
  font-weight: $wt;
}

@mixin classplusButton {
  border-radius: 4px;
  @include flex-full(center, center);
  @include font-wt(500);
  cursor: pointer;
}
@mixin classplusButtonPadding {
  padding: 12px 24px;
}
@mixin classplusButtonPaddingSmall {
  padding: 12px 16px;
}
@mixin disabledButton {
  opacity: 0.5;
  pointer-events: none;
}

@mixin classplusButtonFilledSmall {
  @include font-14();
  @include classplusButton();
  @include classplusButtonPadding;
  min-height: 36px;
  min-width: 80px;
  background: $color-classplus-blue;
  color: $color-pure-white;
  border: none;
}

@mixin classplusButtonFilled {
  @include font-16();
  @include classplusButton();
  min-height: 48px;
  min-width: 180px;
  background: $color-classplus-blue;
  color: $color-pure-white;
  border: none;
}

@mixin classplusButtonOutlineSmall {
  @include font-14();
  @include classplusButton();
  @include classplusButtonPaddingSmall();
  min-height: 48px;
  background: $color-pure-white;
  color: $color-classplus-blue;
  border: 1px solid $color-stroke;
}

@mixin customFont($fSize, $lHt) {
  font-size: $fSize;
  line-height: $lHt;
}
@mixin font-14() {
  @include customFont(14px, 20px);
}
@mixin font-16() {
  @include customFont(16px, 24px);
}

@mixin flex() {
  display: flex;
}

@mixin flex-dir-col() {
  flex-direction: column;
}

@mixin flex-justify($justify) {
  @include flex;
  justify-content: $justify;
}

@mixin flex-align($align) {
  @include flex;
  align-items: $align;
}

@mixin flex-full($justify, $align) {
  @include flex;
  justify-content: $justify;
  align-items: $align;
}

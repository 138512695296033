@import "./variables";

:root {
  --form-control-color: white;
  --form-control-disabled: #959495;
}

.Page {
  margin-top: 56px;
  background-color: white;
  min-height: 96vh;
  padding: 16px;
  font-family: "openSauceOne";

  .Student {
    margin-bottom: 100px;
    font-family: "openSauceOne";
  }
}

// Navbar scss

.Navbar {
  background-color: var(--appAccentColor);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  min-height: 33px;
  display: flex;
  padding: 16px 18px 16px 21px;
  width: 100%;
  cursor: pointer;

  &__Left {
    display: inherit;
    // width: 20%;
    padding-right: 28px;
    align-items: center;
    justify-content: flex-start;
  }

  &__Middle {
    display: inherit;
    // width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #ffffff;
    font-family: "openSauceOne";
  }

  &__Right {
    display: inherit;
    width: 20%;
    justify-content: flex-end;
  }
}

//  InstallMents Card

.InstallmentCard {
  @include flex-full(space-between, flex-start);
  flex-direction: column;

  &__Row1 {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__ImageWrapper {
      display: flex;
      justify-content: center;
      margin-left: 8px;
      img{
        width: 90%;
      }
    }
  }

  &__Row2 {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__SubText {
      margin-left: 40px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #7a8b94;
    }

    &__Receipt {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      column-gap: 8px;
      display: flex;
      align-items: center;
      text-align: right;

      /* Classplus Blue */

      color: #009ae0;
    }
  }

  &:not(:first-child) {
    margin-top: 72px;
  }

  &__installNum {
    @include flex;

    &__installNumText {
      &__num {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #3c4852;
        margin: 0;
      }

      &__date {
        margin: 4px 0;
      }
    }

    &__installNumIcon {
      background: $color-badge-green;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      @include flex-full(center, center);
      color: $color-pure-white;
      @include font-14;
      @include font-wt(600);
      margin-right: 16px;
      position: relative;

      &--withLine {
        &::after {
          content: "";
          height: 80px;
          border-left: 0.866025px dashed rgba(26, 24, 30, 0.4);
          position: absolute;
          top: 32px;
        }
      }
    }
  }

  .blue {
    background: #009ae0;
  }

  &__installPriceInputContainer {
    background: $color-pure-white;
    border: 1px solid $color-stroke;
    padding: 12px;
    border-radius: 4px;
    @include font-16;

    &--negative {
      outline: 2px solid $color-error-red;
    }

    &--disabled {
      background: $color-disabled-grey;

      input {
        background: $color-disabled-grey;
      }
    }

    &__installPriceInput {
      border: none;

      &::placeholder {
        @include font-16;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  &__installPriceText {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3c4852;
    margin: 0;
  }

  &__customDateForm {
    padding: 16px 0;

    &__form {
      @include flex;
      gap: 24px;

      &__input {
        &--60 {
          width: 60%;
        }

        &--40 {
          width: 40%;
        }

        label {
          @include font-14;
          margin-bottom: 8px;
        }

        &__inputContainer {
          padding: 14px 16px;
          background: $color-pure-white;
          border: 1px solid $color-stroke;
          border-radius: 4px;
          min-height: 54.8px;
          @include font-16;
          @include flex;

          &__input {
            border: none;
            width: -webkit-fill-available;
            max-width: 95%;

            &::placeholder {
              @include font-16;
            }

            &:focus {
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }
}

// CompleteCourse Card
.CompleteCourseCard {
  @include flex-full("center", "center");
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border: 1px solid #e9eef2;
  border-radius: 8px;

  &__Header {
    display: grid;
    grid-template-columns: (1.3fr 0.7fr);

    &__Text {
      text-align: left;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Black */

      color: #3c4852;
    }

    &__TP {
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Black */

      color: #3c4852;
    }
  }

  &__SubText {
    margin-top: 8px;

    &--text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* Sub Text */
      color: #7a8b94;
    }

    display: grid;

    grid-template-columns: (1fr 1fr);

    &__PriceSummary {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      text-decoration-line: underline;

      color: var(--appAccentColor);
    }
  }
}

.TutorPaymentDetails {
  margin: 32px 0;

  &__Heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #3c4852;
  }

  &__SubText {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }
}

.DetailsRowCollective {
  margin-bottom: 32px;
}

.hr {
  border: none;
  border-bottom: 1px solid #e9eef2;
  margin: 0;
}

//Points Card
.PointsCard {
  margin-top: 24px;

  ul {
    padding-inline-start: 20px;
    padding-inline-end: 0px;

    li {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #3c4852;
    }
  }
}

//Button
.Button {
  background: var(--appAccentColor);
  border-radius: 8px;
  color: #fafafa;
  text-align: center;
  padding: 12px 10px;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.BottomButton {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 16px;

  &__Right.fullWidth {
    flex-grow: 1;
  }
}

.BottomButton.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BottomSheet {
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 0px 20px 0px;
  z-index: 60;

  &__Head {
    justify-content: space-between;
    display: flex;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 16px 16px 16px;

    &__Icon {
      margin-right: 12px;
    }

    div {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;

      color: #3c4852;
    }
  }

  &__Body {
    padding: 0 16px 0 16px;

    &__Row.Bold {
      margin-bottom: 0px;
      margin-top: 18px;

      div {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-align: right;

        color: #3c4852;
      }
    }

    &__Row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__Price {
        font-style: normal;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        text-align: right;

        color: #3c4852 !important;
      }

      div {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        display: flex;
        align-items: center;

        color: #7a8b94;
      }
    }
  }
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

//Modal

.Modal {
  background: #ffffff;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 32px 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  min-width: 95%;
  min-height: 200px;
  max-width: 95%;
  transform: translate(-50%, -50%);
  justify-content: center;
  z-index: 120;

  &__Image__Container {
    text-align: center;
    margin-bottom: 2px;
  }
  &__Image{
    width: 80px;
    height: 80px;
  }
  &__Status{
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
    color: #7a8b94;
    margin: 0;
    line-height: 20px;
    text-align: center;
    margin: 0 0 1rem 0;
    color: rgba(0, 0, 0, 0.6);
    + .Modal--Action{
        margin-top: 0;
    }
  }
  &__Heading {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    margin: 0 0 1rem 0;
    font-weight: 900;
  }

  &__Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }

  &--Action {
    font-weight: 600;
    text-transform: uppercase;
    color: #009ae0;;
    width: max-content;
    margin: 25px auto auto;
    display: block;
    font-size: 12px;
    cursor: pointer;
  }
}

.Error {
  img {
    width: 15%;
    margin-top: 20%;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.StatesSelectBottomSheet {
  display: flex;
  z-index: 60;

  border-radius: 16px 16px 0px 0px;
  flex-direction: column;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &__Heading {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;
    /* identical to box height, or 150% */
    align-items: flex-start;
    text-align: start;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid #e5e5e5;

    &__left {
      text-align: right;
    }

    &__right {
    }
  }

  &__Body {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    max-height: 50vh;
    overflow: auto;
    margin-bottom: 85px;

    &__Selection {
      display: flex;
      padding: 12px 0;
      justify-content: space-between;

      &__Text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: rgba(0, 0, 0, 0.87);
      }

      &__Radio {
      }
    }
  }
}

.SelectStateCard {
  background: #ffffff;
  /* Stroke */

  border: 1px solid #e9eef2;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  &__left {
    display: flex;
  }

  &__middle {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }
}

//radio

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.13em solid #666666;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--appAccentColor);

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked {
  border: 0.13em solid var(--appAccentColor);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

//checkbox
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.13em solid #666666;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:checked {
  background: var(--appAccentColor);
  border: none;
}

.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

//hourglass

.hourglass {
  display: block;
  background: $hourglassbg;
  margin: 3em auto;
  width: 2em;
  height: 4em;
  animation: hourglass 1s linear infinite;
}

.outer {
  fill: $fill;
}

.middle {
  fill: $hourglassbg;
}

@keyframes hourglass {
  0% {
    transform: rotate(0deg);
    box-shadow: inset $fill 0 -0em 0 0, inset $hourglassbg 0 -2em 0 0,
      inset $fill 0 -4em 0 0;
  }

  80% {
    transform: rotate(0deg);
    box-shadow: inset $fill 0 -2em 0 0, inset $hourglassbg 0 -2em 0 0,
      inset $fill 0 -2em 0 0;
  }

  100% {
    transform: rotate(180deg);
    box-shadow: inset $fill 0 -2em 0 0, inset $hourglassbg 0 -2em 0 0,
      inset $fill 0 -2em 0 0;
  }
}

/* CUSTOM FONT FACES FOR THE APP */
@font-face {
  font-family: "openSauceOne";
  src: url("./fonts/OpenSauceOne/400.ttf"), local(system-ui);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "openSauceOne";
  src: url("./fonts/OpenSauceOne/500.ttf"), local(system-ui);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "openSauceOne";
  src: url("./fonts/OpenSauceOne/600.ttf"), local(system-ui);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "openSauceOne";
  src: url("./fonts/OpenSauceOne/700.ttf"), local(system-ui);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body,
button,
input {
  font-family: "openSauceOne";
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

* {
  box-sizing: border-box;
  font-family: "openSauceOne";
}

/* body{ 
    border: 2px solid #fff7f8;
   padding: 100px; 
 } */

.shimmer-bottom-fixed {
  position: fixed;
  bottom: 0;
}

.shimmer-shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

cashcard {
  height: 70px;
  width: 100%;
}

box {
  height: 104px;
  width: 100px;
}

/*  
 div {
   display: inline-flex;
   flex-direction: column; 
   margin-left: 25px;
   margin-top: 15px;
   vertical-align: top; 
 } */

lines {
  height: 20px;
  margin-top: 10px;
  width: 100%;
}

para {
  display: block !important;
  width: 325px;
  height: 80px;
  margin-top: 40px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.shimmer-container {
  display: flex;
  flex-direction: column;
  margin: 24px;
  margin-top: 56px;
  padding-top: 16px;
}

.shimmer-left {
  width: 100%;
  margin: 0;
}

.shimmer-right {
  width: 100%;
}

.shimmer-bottom-fixed {
  position: initial;
}
